import React, {useState, useEffect} from 'react';
import toast, {Toaster} from 'react-hot-toast';
import {onMessageListener} from './firebaseConfig';

const NotificationFirebase = () => {
    const [notification, setNotification] = useState({title: '', body: ''});

    useEffect(() => {
        onMessageListener()
            .then((payload) => {
                if (payload?.notification?.title && payload?.notification?.body) {
                    setNotification({
                        title: payload.notification.title,
                        body: payload.notification.body
                    });
                } else {
                    console.log('Notification incomplète: ', payload);
                }
            })
            .catch((err) => console.log('Erreur lors de la réception de la notification: ', err));
    }, []);

    useEffect(() => {
        if (notification?.title && notification?.body) {
            toast.promise(
                Promise.resolve(),
                {
                    loading: 'Chargement de la notification...',
                    success: notification.title + "\n " + notification.body,
                    error: 'Erreur lors de l\'affichage de la notification'
                }
            );
        }
    }, [notification]);

    return (
        <Toaster/>
    );
}

export default NotificationFirebase;
