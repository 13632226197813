import React from "react";
import {toast} from "react-toastify";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Met à jour l'état afin que le prochain rendu montre l'UI de repli.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // Vous pouvez également enregistrer l'erreur dans un service de reporting ici.
        toast.error("Une erreur est survenue avec le système de notifications.");
        console.error("ErrorBoundary caught an error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{padding: "20px", textAlign: "center"}}>
                    <h1>Quelque chose s'est mal passé.</h1>
                    <p>Le système de notifications rencontre un problème.</p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
