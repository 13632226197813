import TextField from "@mui/material/TextField";
import {styled} from "@mui/material/styles";

/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mon compte';
export const titleAlveole = 'Mon compte';

/************************ DeV ******************************************************/
// Récupération des infos WP

export const zUrlBackNotifications = process.env.REACT_APP_API_SERVICENOTIFICATION;
export const zUrlBack = process.env.REACT_APP_API_PROFILE;
export const domain = process.env.REACT_APP_DOMAIN;

/************************ LOCAL DeV ************************************************/
// export const zUrlBackNotifications = "https://localhost:8001";
// export const domain = 'localhost';
// export const zUrlBack = "https://localhost:8000";
/**
 * Fin constantes spécifiques alvéole
 */

/************************ Constantes ***********************************************/
export const zURLhome = process.env.REACT_APP_HOME_CUSTOMER;

export const CristalTextMessage = styled(TextField)(({theme}) => ({
    'margin': '8px 0',
    '& label': {
        fontSize: '16px',
        color: theme.palette.mode === 'light' ? '#00508F!important' : '#ffffff!important',
        fontFamily: 'PoppinsRegular',
    },
    '& input': {
        paddingRight: '18px', // Ajustement pour le label
    },
    '& textarea': {
        fontFamily: 'PoppinsRegular',
    },
    '& fieldset': {
        fontSize: '16px',
        borderColor: theme.palette.mode === 'light' ? '#00508F' : '#ffffff',
        borderRadius: '10px',
    },
    '& .MuiInputBase-adornedEnd': {
        paddingRight: '16px'
    }
}));
